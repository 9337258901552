import React from 'react';
import { Button, message } from 'antd';
import { navigate } from 'gatsby';
import axios from 'axios';
import {addons} from "../../data/addons";

const AddonCheckout = ({ plan, userId, planType, monthlyPrice, annualPrice, addonId, selectedProjectId }) => {
      const redirectToCheckout = () => {
            message.info('Redirecting to payment page...');
            let rewardfulReferenceId = ''
            window.rewardful('ready', function() {
                  if (window.Rewardful && window.Rewardful.referral){
                        rewardfulReferenceId = window.Rewardful.referral;
                  }
                });
            
            
            const addon = addons.find((addon)=>{
                  if (addon.id === addonId) {
                        return addon;
                  }
            })
            

            const data = JSON.stringify({
                  clientReferenceId: userId,
                  price: plan,
                  addonId: addonId,
                  projectId: selectedProjectId,
                  addonName: addon.name,
                  addonDescription: addon.description,
                  rewardfulReferenceId
            });
          
            const config = {
                  method: 'post',
                  url: `${process.env.GATSBY_BASE_URL}/create_addon_checkout_link`,
                  headers: {
                        'Content-Type': 'application/json',
                  },
                  data,
            };

            axios(config)
                  .then(function (response) {
                        const { url } = response.data;
                        window.open(url);
                  })
                  .catch(function (error) {
                        console.log(error);
                  });
      };

      return (
            <>
                  <Button
                        type={planType === 'monthly' && 'primary'}
                        block
                        onClick={() => redirectToCheckout()}
                        style={{
                              fontSize: 13,
                              fontWeight: 400,
                        }}
                  >
                        {planType === 'monthly'
                              ? `Pay Monthly — $${monthlyPrice}`
                              : `Pay Annually — $${
                                    annualPrice
                                }`}
                  </Button>
            </>
      );
};

export default AddonCheckout;
