import React from 'react';
import { Router } from '@reach/router';

import AddonPlans from '../../blocks/components/addonPlans';
import SEO from '../../blocks/layout/seo';
import AppContainer from '../../blocks/layout';
// markup
const Addons = ({ location }) => {
      return (
            <>
                <SEO title="Addons Pricing Plan — NoCodeAPI" />
                <AppContainer activeNav="plan" location={location}>
                    <Router>
                            <AddonPlans
                                location={location}
                                path="/addons/plans/:addonId"
                            />
                    </Router>
                </AppContainer>
            </>
      );
};

export default Addons;
