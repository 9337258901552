export const pricingPlans = [
    {
          plan: 'Basic Addon Plan',
          description:
                'Standard Addon Plan.',
          monthlyPrice: 12,
          save: 38,
          yearlyPrice: 70,
          planType: 'basic',
    },
];
