import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import { Button, message } from 'antd';


import { pricingPlans } from '../../data/addons/plans';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { Firebase } from '../../config/firebase';
import { getAddonPlanId } from '../../config/custom/functions';
import AddonCheckout from '../../blocks/codes/addonCheckout';

import '../../styles/plan.scss';

const AddonPlans = ({addonId, location}) => {
      const [planType, setPlanType] = useState('yearly');
      const [loading, setLoading] = useState(false);
      const [userPlan, setUserPlan] = useState('Free');
      const [custID, setCustID] = useState('');
    //   const [addonId, setAddonId] = useState(addonId);
      const state = useContext(GlobalStateContext);
      const { user, selectedProjectId } = state;
      const [uid, setUid] = useState('');

      useEffect(() => {
            setLoading(true);
            async function fetchUserSubscription(userId) {
                  const subRes = await axios.get(
                        `${process.env.GATSBY_BASE_URL}/subscriptions/addons/${userId}/${addonId}`
                  );
                  setUid(user.uid);
                  const { message } = subRes.data;
                  if (message === 'No subscription') setUserPlan('Free');
                  if (
                        user.addons!==undefined && user.addons !== null
                        && subRes.data && subRes.data[0]?.addons && subRes.data[0]?.addons[0]?.customer_stripe
                  ) {
                        const customer = subRes.data[0].addons[0].customer_stripe;
                        setCustID(customer.customer_id);
                        setUserPlan(customer.price_id)
                         
                  } else {
                        setUserPlan('Free');
                  }
                  setLoading(false);
                  if (user.notFound) {
                        navigate('/dashboard/setup');
                  }
            }
            Firebase.auth().onAuthStateChanged((firebaseUser) => {
                  if (firebaseUser && user !== null && user !== undefined) {
                        fetchUserSubscription(user.uid);
                  }
            });
      }, [user]);
      return (
            <>
                        {loading ? (
                              'Loading...'
                        ) : (
                              <div className="page-content-header">
                                    <h2>Plan & Pricing</h2>
                                    <p>
                                          Your current plan and options to
                                          upgrade for high plan.
                                    </p>
                                    <>
                                          <div className="annually-note">
                                                <b
                                                      style={{
                                                            backgroundColor:
                                                                  '#ffcd58',
                                                            color: '#000',
                                                            padding: '0px 3px',
                                                            borderRadius: 3,
                                                      }}
                                                >
                                                      Note:
                                                </b>{' '}
                                                Save 2-months on annually
                                                subscription
                                          </div>
                                          <div className="list-of-all-plans">
                                                {pricingPlans.map(
                                                      (planObj, key) => (
                                                            <div
                                                                  key={key}
                                                                  className={'plan-card'}
                                                            >
                                                                  <div className="name">
                                                                        {
                                                                              planObj.plan
                                                                        }
                                                                  </div>
                                                                  <div className="price">
                                                                        $
                                                                        {
                                                                              planObj.monthlyPrice
                                                                        }
                                                                        <span>
                                                                              /month
                                                                        </span>
                                                                  </div>
                                                                  <div
                                                                        className="description"
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: planObj.description,
                                                                        }}
                                                                  />
                                                                 {(userPlan !== getAddonPlanId(addonId,planObj.planType,'monthly')) && (
                                                                        <>
                                                                              <AddonCheckout
                                                                                    userId={`${uid}-addon-${addonId}-${planObj.plan}`}
                                                                                    btnText={`Pay with Debit or Credit Card`}
                                                                                    plan={getAddonPlanId(
                                                                                          addonId,
                                                                                          planObj.planType,
                                                                                          'monthly'
                                                                                    )}
                                                                                    addonId={addonId}
                                                                                    selectedProjectId={selectedProjectId}
                                                                                    planType="monthly"
                                                                                    monthlyPrice={
                                                                                          planObj.monthlyPrice
                                                                                    }
                                                                                    annualPrice={planObj.yearlyPrice}
                                                                                    planName={
                                                                                          planObj.plan
                                                                                    }
                                                                              />
                                                                             
                                                                        </>
                                                                  )}
                                                                  
                                                                  {(userPlan !== getAddonPlanId(addonId,planObj.planType,'yearly'))
                                                                  && (
                                                                        <>
                                                                            
                                                                               <br />
                                                                               <br />
                                                                               <AddonCheckout
                                                                                     userId={`${uid}-addon-${addonId}-${planObj.plan}`}
                                                                                     btnText={`Pay with Debit or Credit Card`}
                                                                                     plan={getAddonPlanId(
                                                                                           addonId,
                                                                                           planObj.planType,
                                                                                           'yearly'
                                                                                     )}
                                                                                     selectedProjectId={selectedProjectId}
                                                                                     addonId={addonId}
                                                                                     planType="yearly"
                                                                                     monthlyPrice={
                                                                                           planObj.monthlyPrice
                                                                                     }
                                                                                     annualPrice={planObj.yearlyPrice}
                                                                                     planName={
                                                                                           planObj.plan
                                                                                     }
                                                                               />
                                                                               </>
                                                                        )}

                                                                        
                                                                
                                                            </div>
                                                      )
                                                )}
                                          </div>
                                         
                                    </>
                              </div>
                        )}
               
            </>
      );
};

export default AddonPlans;
